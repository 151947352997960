@import '../../../styles/customMediaQueries.css';

.label,
.labelSelected {
  display: inline-block;
  width: auto;
  height: 35px;
  min-height: 35px;

  padding: 0 18px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;
  white-space: nowrap;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.label {
  /* Button borders */
  border: 1px solid var(--colorGrey200);

  background-color: var(--colorWhite);
  margin-left: 15px;
  font-family: 'Avenir Pro Black', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1c1b1b;
  white-space: nowrap;

  &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
  }

  &:disabled {
    border-color: var(--colorGrey100);
  }

  @media (--viewportMedium) {

    &:hover,
    &:focus {
      border-color: var(--marketplaceColor);
    }
  }
}

.labelSelected {
  /* Button borders */
  border: 1px solid var(--marketplaceColor);
  margin-left: 15px;
  font-family: 'Avenir Pro Black', sans-serif;
  font-weight: 600;
  font-size: 14px;

  /* Button colors: secondary */
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--colorGrey100);
    color: var(--colorWhite);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  position: relative;
  margin-left: 12px;
  transition: all 0.2s ease-out;

}

.iconSelected {
  color: #FFFFFF;
}

.iconArrowAnimation {
  transform: rotate(-180deg);
}
